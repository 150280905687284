<template>
  <v-container
    class="align justify"
    fluid
  >
    <v-card
      :color="this.$store.state.darkTheme ? card_bg_dark : card_bg_light"
      :dark="this.$store.state.darkTheme ? true : false"
      :class="isMobile()?'my-2':'my-2 mx-2'"
      min-width="300px"
    >
      <v-card-title class="gmiddleblue">
        My mailing list
        <v-btn
          absolute
          right
          :disabled="this.$store.state.ml_event_change?false:true"
          :text="this.$store.state.ml_event_change?false:true"
          :color="this.$store.state.ml_event_change?'gred':'primary'"
          @click.native="saveChange"
        >
          <v-icon left>
            fas fa-save
          </v-icon>
          Save
        </v-btn>
      </v-card-title>

      <div class="div-table">
        <mailing-list />
      </div>
    </v-card>
    <br><br><br>
  </v-container>
</template>

<script>
  import config from '@/config'
  import MailingList from '@/components/table/MailingList'
  import axios from 'axios'
  import checkToken from '../store/checkToken'
  export default {
    components: {
      MailingList,
    },

    data: () => ({
      // Couleur
      red: config.colors.gricad_red_dark1,
      card_bg_dark: config.colors.card_bg_dark,
      card_bg_light: config.colors.card_bg_light,
      barchart_color: config.colors.gricad_blue_dark3,
      blue: config.colors.gricad_oceanblue,

    }),
    created () {
    },
    methods: {
      isMobile () {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },
      saveChange () {
        var token = $cookies.get('token')
        var validToken = checkToken(token)
        if (!validToken) {
          this.$router.push('/login')
        } else {
          this.$store.state.listMailingList = []
          for (var index = 0; index < this.$store.state.mailingList.length; index++) {
            if (this.$store.state.mailingList[index].sub) {
              this.$store.state.listMailingList.push(this.$store.state.mailingList[index].title)
              axios.get('/group/' + this.$store.state.mailingList[index].title + '/add-member/' + this.$store.state.account.login)
            } else {
              axios.get('/group/' + this.$store.state.mailingList[index].title + '/delete-member/' + this.$store.state.account.login)
            }
          }
          this.$store.state.ml_event_change = false
        }
      },

    },
  }
</script>

<style lang="scss">
  .div-table {
    overflow-x: auto;
    margin-right: 2pc;
  }
</style>
